import MuiCard from "@mui/material/Card"
import MuiCardContent from "@mui/material/CardContent"
import MuiCardActions from "@mui/material/CardActions"
import MuiCardHeader from "@mui/material/CardHeader"
import MuiCardMedia from "@mui/material/CardMedia"
import { styled } from "@mui/material/styles"

export const Card = styled(MuiCard)(() => ({
  boxShadow: "none",
}))

export const CardContent = styled(MuiCardContent)(() => ({
  background: "#FEFFFE",
}))

export const CardActions = styled(MuiCardActions)(() => ({}))

export const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  background: "#E6FCFC",
  borderBottom: "2px solid #7FC7C6",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(4),
}))

export const CardMedia = styled(MuiCardMedia)(() => ({}))
