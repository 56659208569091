import React, { ReactElement } from "react"
import T from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { Card, CardHeader } from "../../components/Card"
import Grid from "@mui/material/Grid"
import CardActionArea from "@mui/material/CardActionArea"
import MuiPersonIcon from "@mui/icons-material/Person"
import { navigate } from "gatsby"
import useUserWithSignInRedirect from "../../hooks/useUserWithSignInRedirect"
import MuiPasswordIcon from "@mui/icons-material/LockReset"
import { useId } from "@reach/auto-id"

const FlexCard = styled(Card)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
}))

const PersonIcon = styled(MuiPersonIcon)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 4,
  marginBottom: theme.spacing(-1),
  color: theme.palette.primary.main,
}))

const PasswordIcon = styled(MuiPasswordIcon)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 4,
  marginBottom: theme.spacing(-1),
  color: theme.palette.primary.main,
}))
interface AdminLinkButtonProps {
  title: ReactElement | string
  icon: ReactElement
  to: string
}

const AdminLinkButton = ({
  title,
  icon,
  to,
}: AdminLinkButtonProps): ReactElement => {
  const testId =
    typeof title === "string" &&
    title.replace(/\s+/g, "-").toLowerCase() + "-admin-button"
  const autoTestId = useId()

  return (
    <Grid item xs={6} md={3} data-test-id={testId ? testId : autoTestId}>
      <FlexCard>
        <CardActionArea
          onClick={() => {
            navigate(to)
          }}
        >
          <CardHeader
            sx={{ textAlign: "center" }}
            title={
              <>
                {icon}
                <T component="h3" sx={{ mb: -1 }}>
                  {title}
                </T>
              </>
            }
          />
        </CardActionArea>
      </FlexCard>
    </Grid>
  )
}

const AdminHomePage = (): ReactElement => {
  const { user, admin } = AdminHomePage.useUserWithSignInRedirect()

  if (!user) return <></>

  return (
    <>
      <T variant="h1" component="h1">
        My Cambridge Application
      </T>
      <T variant="body2">
        You are signed in and not currently viewing an application.
      </T>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {(admin.roles?.impersonation || admin.roles?.audit) && (
          <AdminLinkButton
            title="View application"
            icon={<PersonIcon />}
            to="./applications"
          />
        )}
        <AdminLinkButton
          title="Change your password"
          icon={<PasswordIcon />}
          to="/account/change-password"
        />
      </Grid>
    </>
  )
}

// Setting these as static members allows us to mock during component testing
AdminHomePage.useUserWithSignInRedirect = useUserWithSignInRedirect

export default AdminHomePage
